
import { Component, Ref, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import Widget from '@/components/Widget/Widget.vue'
import FinancialPlanReforecast from '@/models/FinancialPlanReforecast'
import IconAction from '@/components/IconAction/IconAction.vue'
import FinancialPlanReforecastRecords from '@/models/FinancialPlanReforecastsRecords'
import Api from '@/models/Api'
import Fields from './financial-plan-reforecast-fields'
import ReforecastEditModal from './components/ReforecastEditModal.vue'

@Component({
  components: {
    DataTable,
    Widget,
    IconAction,
    ReforecastEditModal,
  },
})
export default class GoalReforecastView extends ViewModel {
  @Ref() public dataTable!: any

  @Ref() public reforecastEditModal!: any

  @Prop({ required: true })
  public year!: any

  public ready = false

  public fieldFilters: any = {}

  public records: number = 0

  public is_managing: boolean = false

  public modal: boolean = false

  public reforecasts: FinancialPlanReforecast[] = []

  public get fields() {
    return Fields
  }

  public mounted() {
    this.ready = true
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    return FinancialPlanReforecast.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, `year:${this.year}`],
      ...this.fieldFilters,
    }).then((response: any) => {
      this.loading = false
      this.records = response.records
      this.reforecasts = response.data
      return response.data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public create() {
    this.modal = true
    setTimeout(() => {
      // this.reforecastEditModal.create_new = true
      // this.reforecastEditModal.setMonthTable()
      this.getGoalRef()
    }, 200)
  }

  public view(e: any) {
    this.modal = true

    FinancialPlanReforecastRecords.getRecords({ reforecast_id: e.id }).then((response: any) => {
      this.reforecastEditModal.parent = e
      this.reforecastEditModal.used_value = e.goal
      this.reforecastEditModal.create_new = false
      this.reforecastEditModal.is_editing = false
      this.reforecastEditModal.goal_refs = response
      this.reforecastEditModal.setMonthTable()
    })
  }

  public edit(e: any, cb: any = null) {
    this.modal = true

    FinancialPlanReforecastRecords.getRecords({ reforecast_id: e.id }).then((response: any) => {
      this.reforecastEditModal.used_value = e.goal
      this.reforecastEditModal.parent = e

      if (cb) {
        cb(response)
      } else {
        this.reforecastEditModal.create_new = false
        this.reforecastEditModal.is_editing = true
        this.reforecastEditModal.goal_refs = response
        this.reforecastEditModal.setMonthTable()
      }
    })
  }

  public getGoalRef() {
    if (this.reforecasts.length) {
      // find the reforecast with the highest reforecast.revision
      const found = this.reforecasts.reduce((a, b) => (a.revision > b.revision ? a : b))
      this.edit(found, (response: any) => {
        this.reforecastEditModal.create_new = true
        this.reforecastEditModal.is_editing = false
        this.reforecastEditModal.goal_refs = response
        this.reforecastEditModal.parent.name = null
        this.reforecastEditModal.parent.description = null
        this.reforecastEditModal.parent.id = null
        this.reforecastEditModal.setMonthTable()
      })
    } else {
      // get from main goal
      let api = new Api()
      api.get(`financial_plan/finance_goal/${this.$route.params.goal}`).then((response: any) => {
        // sum goal from response
        this.reforecastEditModal.used_value = response.data.result.reduce(
          (a: any, b: any) => a + b.goal,
          0,
        )
        this.reforecastEditModal.used_value = this.reforecastEditModal.used_value.toFixed(2)
        this.reforecastEditModal.create_new = true
        this.reforecastEditModal.is_editing = false
        this.reforecastEditModal.goal_refs = response.data.result
        this.reforecastEditModal.setMonthTable()
      })
    }
  }
}
