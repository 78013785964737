
import DataTable from '@/components/DataTable/index.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import FinancialPlan from '@/models/FinancialPlan'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import { currencyMask, percentageMask } from '@/models/interface/Masks'
import { groupBy } from 'lodash'
import moment from 'moment'
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import FinancialPlanProductModule from '@/models/FinancialPlanProductModule'
import FinancialPlanReforecast from '@/models/FinancialPlanReforecast'
import SalesRepConsolidateTable from './SalesRepConsolidateTable.vue'

@Component({
  components: {
    IconAction,
    DataTable,
    FormInput,
    UserPicker,
    SalesRepConsolidateTable,
  },
})
export default class ReforecastEditModal extends ViewModel {
  @Ref() public validator!: any

  @Prop({ default: 0 })
  public year!: number

  @Prop({ default: false })
  public modal!: boolean

  public used_value: any = 0

  public parent: FinancialPlanReforecast = new FinancialPlanReforecast()

  public increment: number = 0

  public item: any = []

  public goalModule = new FinancialPlanProductModule()

  public can_collapse: boolean = false

  public loading: boolean = false

  public goal_refs: any = []

  public locked_items: any = []

  public locked_values: any = []

  public bulk_months: any = []

  public is_editing: boolean = true

  public collapsed_groups: any = {
    ssl: false,
    ccl: false,
  }

  public sales_rep_consolidate_fields: any = [
    {
      key: 'month',
      label: 'Month',
      sortable: false,
      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
    {
      key: 'ssl',
      label: 'SSL',
      sortable: false,
      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
    {
      key: 'ccl',
      label: 'CCL',
      sortable: false,

      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
    {
      key: 'total',
      label: 'Total',
      sortable: false,
      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
  ]

  public top_header_fields: any = [
    {
      key: 'month',
      label: '',
      sortable: false,
      filter: false,
      show: true,
      style: 'width: 300px',
      thClass: 'text-start',
      colspan: 1,
    },
    {
      key: 'ssl',
      label: 'SSl',
      sortable: false,
      filter: false,
      show: true,
      colspan: 5,
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl',
      label: 'CCl',
      sortable: false,
      filter: false,
      show: true,
      colspan: 5,
      class: 'align-middle text-center border',
    },
    {
      key: 'total',
      label: '',
      sortable: false,
      filter: false,
      show: true,
    },
  ]

  public sub_header_fields: any = [
    {
      key: 'month',
      label: '',
      show: true,
      class: 'align-middle text-left border',
    },
    {
      key: 'ssl_east',
      label: 'East',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_west',
      label: 'West',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_midwest',
      label: 'Midwest',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_national',
      label: 'national',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_total',
      label: 'Total',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },

    {
      key: 'ccl_east',
      label: 'East',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_west',
      label: 'West',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_midwest',
      label: 'Midwest',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_national',
      label: 'National',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_total',
      label: 'Total',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'total',
      label: 'Total',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
      type: 'currency',
    },
  ]

  public temp_goal: any = []

  public saving: boolean = false

  public create_new: boolean = true

  public viewing: any = {
    main_table: true,
  }

  public get loading_state() {
    return this.loading || this.saving || !this.ready
  }

  public get masks() {
    return {
      currency: currencyMask,
      percentage: percentageMask,
    }
  }

  public get header_fields() {
    return this.top_header_fields.filter((item: any) => item.show)
  }

  public get sub_head_fields() {
    return this.sub_header_fields.filter((item: any) => item.show)
  }

  public ready: any = false

  public get local_modal() {
    return this.modal
  }

  public set local_modal(val) {
    this.$emit('update:modal', val)
  }

  public mounted() {
    // this.setMonthTable()
  }

  public searchMonths(arr: any, month: string, product: string, return_undef = false) {
    let found = arr.filter(a => {
      let splited = a.period_start_at.split('T')
      return moment(splited[0]).format('MMMM') === month && a.product === product
    })
    if (!found && !return_undef) return { goal: null }
    return found
  }

  public searchMonth(arr: any, month: string, product: string, region: any, return_undef = false) {
    let found = arr.find(a => {
      let splited = a.period_start_at.split('T')
      return moment(splited[0]).format('MMMM') === month && a.product === product && a.region
    })
    if (!found && !return_undef) return { goal: 0 }
    return found
  }

  public setMonthTable(pre_load: boolean = true) {
    let months = moment.months().map((monthName: string) => {
      let obj: any = {
        month: monthName,
        ccl: {
          east: 0,
          west: 0,
          midwest: 0,
          national: 0,
          total: 0,
        },
        ssl: {
          east: 0,
          west: 0,
          midwest: 0,
          national: 0,
          total: 0,
        },
        total: 0,
      }
      let ssl_goals = this.searchMonths(this.goal_refs, monthName, 'ssl')
      let ccl_goals = this.searchMonths(this.goal_refs, monthName, 'ccl')
      ssl_goals = ssl_goals.map((ssl_goal: any) => ({
        [ssl_goal.region]: ssl_goal.goal,
      }))
      ccl_goals = ccl_goals.map((ccl_goal: any) => ({
        [ccl_goal.region]: ccl_goal.goal,
      }))

      Object.assign(obj.ssl, ...ssl_goals)
      Object.assign(obj.ccl, ...ccl_goals)

      return obj
    })

    this.temp_goal = months

    this.ready = true
  }

  public cancelEdition() {
    this.local_modal = false
    this.saving = false
    this.parent = new FinancialPlanReforecast()
    this.used_value = 0
    this.create_new = false
    this.is_editing = false
    this.goal_refs = []
  }

  public async saveAll() {
    this.saving = true
    this.loading = true
    let payload: any = this.temp_goal
    if (this.parent?.id) {
      payload = []
      // format temp_goal to update
      this.temp_goal.forEach((goal: any) => {
        let found_ssl = this.searchMonths(this.goal_refs, goal.month, 'ssl', true)
        let found_ccl = this.searchMonths(this.goal_refs, goal.month, 'ccl', true)
        found_ssl.forEach((ssl: any) => {
          payload.push({
            id: ssl.id,
            goal: goal.ssl[ssl.region],
          })
        })
        found_ccl.forEach((ccl: any) => {
          payload.push({
            id: ccl.id,
            goal: goal.ccl[ccl.region],
          })
        })
      })
    }

    let obj: any = {
      name: this.parent.name,
      description: this.parent.description,
      payload,
    }

    FinancialPlanReforecast.save(obj, this.year, this.parent?.id || null)
      .then(response => {
        this.loading = false
        this.$emit('saved')
        this.cancelEdition()
      })
      .catch(() => {
        this.loading = false
        this.saving = false
      })
  }

  public async distibuteGoal(action: string, increment: any = null) {
    if (!this.is_editing && !this.create_new) return
    this.loading = true

    let value_use: number = Number(this.used_value.toFixed(2))

    let copy: any = JSON.parse(JSON.stringify(this.temp_goal))

    await this.goalModule
      .distibuteGoal(copy, action, increment, value_use)
      .then(updated_vals => {
        Vue.set(this, 'temp_goal', updated_vals)

        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
}
