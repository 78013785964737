import numeral from 'numeral'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Company from './Company'
import User from './User'
import DatePickerDate from './DatePickerDate'
import Api from './Api'
import WebMessage from './WebMessage'
import ModelWithFiles from './interface/ModelWithFiles'

export default class FinancialPlanReforecastRecords extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'financial_plans_reforecast_record' as string | null,
      plural: 'financial_plans_reforecast_record' as string | null,
    },
  }

  public id: any = null

  public parent_id: any = null

  public year: number = moment().add(1, 'year').year()

  public region: null | string = null

  public agency_id: null | string = null

  public agency: null | Company = null

  public sales_management_id: null | string = null

  public sales_management: null | User = null

  public sales_rep_id: null | string = null

  public sales_rep: null | User = null

  public period_start_at: null | string = null

  public period_end_at: null | string = null

  public product: null | string = null

  public snapshot_id: null | string = null

  public goal: number = 0

  public goal_planned: number = 0

  public goal_pipeline: number = 0

  public goal_achieved: number = 0

  public goal_sold: number = 0

  public last_year_goal: number = 0

  public last_year_goal_planned: number = 0

  public last_year_goal_pipeline: number = 0

  public last_year_goal_sold: number = 0

  public last_year_goal_achieved: number = 0

  public metrics: any = null

  public type: string = 'annual_goal'

  public _showDetails = false

  public _loading = false

  public filter_by: any = {
    period: null,
  }

  public get period_dates() {
    return new DatePickerDate(this.period_start_at, this.period_end_at, null, 'YYYY-MM-DD HH:mm:ss')
  }

  public set period_dates(value: DatePickerDate) {
    this.period_start_at = value.start
    this.period_end_at = value.end
  }

  protected onSave(response: any, meta: any = null) {
    WebMessage.success('Financial Plan saved')

    return response
  }

  public get view_goal(): string {
    return numeral(this.goal).format('$0.00a')
  }

  public get planned_percent(): number {
    if (this.goal > 0 && this.goal_planned > 0) {
      return this.goal_planned / this.goal
    }
    return 0
  }

  public get view_goal_planned(): string {
    let percent = 0
    if (this.goal > 0 && this.goal_planned > 0) {
      percent = (this.goal_planned / this.goal) * 100
    }

    return `${numeral(this.goal_planned).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_goal_pipeline(): string {
    let ref = this.goal_planned

    if (this.type === 'sales_rep_goal') {
      ref = this.goal
    }

    let percent = 0
    if (this.goal > 0 && this.goal_pipeline > 0) {
      percent = (this.goal_pipeline / ref) * 100
    }

    return `${numeral(this.goal_pipeline).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_goal_sold(): string {
    let ref = this.goal_pipeline

    if (this.type === 'sales_rep_goal') {
      ref = this.goal
    }

    let percent = 0
    if (this.goal > 0 && this.goal_sold > 0) {
      percent = (this.goal_sold / ref) * 100
    }

    return `${numeral(this.goal_sold).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_goal_achieved(): string {
    let percent = 0
    if (this.goal > 0 && this.goal_achieved > 0) {
      percent = (this.goal_achieved / this.goal) * 100
    }
    return `${numeral(this.goal_achieved).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_not_achieved(): string {
    return numeral(this.goal_planned - this.goal_achieved).format('$0.00a')
  }

  public get period(): string {
    const start = moment(this.period_start_at)
    const end = moment(this.period_end_at)

    if (start.isSame(end, 'month')) {
      return `${start.format('MMM, YYYY')}`
    }

    return `${start.format('MMM, YYYY')} - ${end.format('MMM, YYYY')}`
  }

  public get isQuarterly() {
    return this.filter_by.period === 'quarterly'
  }

  public get isLoading() {
    return this._loading
  }

  public toObject(source: any) {
    let instance = this.clone()

    Object.assign(instance, source)

    if (source.agency) {
      instance.agency = Company.toObject(source.agency)
    }

    if (source.sales_management) {
      instance.sales_management = User.toObject(source.sales_management)
    }

    if (source.sales_rep) {
      instance.sales_rep = User.toObject(source.sales_rep)
    }

    return instance
  }

  public get apiData() {
    return {
      id: this.id,
      parent_id: this.parent_id,
      year: this.year,
      region: this.region,
      agency_id: this.agency_id,
      sales_management_id: this.sales_management_id,
      sales_rep_id: this.sales_rep_id,
      period_start_at: this.period_start_at
        ? moment(this.period_start_at).format('YYYY-MM-DD')
        : null,
      period_end_at: this.period_end_at ? moment(this.period_end_at).format('YYYY-MM-DD') : null,
      product: this.product,
      goal: this.goal,
      goal_planned: this.goal_planned,
      goal_achieved: this.goal_achieved,
      type: this.type,
      metrics: [], // TODO after the metric logic is added  chanch the way things is loaded by levels (scope), also remove the orWhere from the backend
      files: this.files,
    }
  }

  public static async getRecords({ reforecast_id }: any) {
    let api = new Api()

    return api.get(`financial_plans_reforecast_records/parent/${reforecast_id}`).then(
      response =>
        // console.log('getRecords', response.data)
        response.data.result,
    )
  }
}
