import router from '@/Routes'
import FinancialPlan from '@/models/FinancialPlan'
import FinancialPlanReforecast from '@/models/FinancialPlanReforecast'
import WebMessage from '@/models/WebMessage'

export default [
  {
    key: 'year',
    label: 'Year',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
    thStyle: 'width:200px',
  },
  {
    key: 'revision',
    label: 'Revision',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'description',
    label: 'Description',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'goal',
    label: 'Plan',
    type: 'currency',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'user',
    label: 'Created By',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
    type: 'object',
    object: {
      key: 'user',
      property: 'name',
    },
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    filter: false,
    show: true,
    type: 'date',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    type: 'action_list',
    sortable: false,
    show: true,
    tdClass: 'actions',
    thStyle: { width: '200px' },
    actions: [
      {
        icon: 'eye',
        title: 'View',
        event: 'view',
        top_level: true,
      },
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
      },
    ],
  },
]
